/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
  var pageHeader = $(".panel-banner");
  var ajaxURL = site.ajaxurl;
  var post_title = site.post_title;
  //var gmap_address = site.address;
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        console.log("common");

        function initGlobalfunc() {
          setTimeout(function() {
            if ($(".single-event-section")[0]) {
              //remove class first
              $('.side-entry').affix({
                offset: {
                  top: $('.entry-content').offset().top - 200,
                  bottom: $('.footer').outerHeight() + 50
                }
              });
            }
            AOS.init({
              offset: 200,
              duration: 600,
              easing: 'ease-in-sine',
              delay: 100,
              disable: 'mobile',
            });
          }, 500);
          //youtube modal
          $(function() {
            $(".youtube_player").YouTubeModal({
              autoplay: 1,
              width: 640,
              height: 480
            });
          });
          //scroll to top of page
          $('.p-circle').on('click', function() {
            $('html,body').animate({
              scrollTop: $(this).offset().top + 90
            }, 600);
          });
          $('.readmore a').hover(function() {
            $(this).closest('.article').addClass('active');
          }, function() {
            $(this).closest('.article').removeClass('active');
          });
          //mobile menu
          var isLateralNavAnimating = false;
          //open/close lateral navigation
          $('.hamburger-menu').on('click', function() {
            $('.bar').toggleClass('animate');
            $('.mobilemenu').toggleClass('active');
             $('body').toggleClass('_open');
            $('.menu-overlay').toggleClass('darken');
          });
          $('.menu-overlay').on('click', function() {
            $('.bar').removeClass('animate');
            $('.mobilemenu').removeClass('active');
             $('body').removeClass('_open');
            $(this).removeClass('darken');
          });
          
          
          $('.read_more-btn').click(function(){ 
            //you can give id or class name here for $('button')
              $(this).text(function(i,old){
                  return old=='Close' ?  'Read More' : 'Close';
              });
          });
        
        
      // Don't forget to init the view!
            Homepage.init();
          
        }
       var Homepage = Barba.BaseView.extend({
          namespace: 'toyota-cps-warranty-training-website ',
          onEnter: function() {
              // The new Container is ready and attached to the DOM.
            $('.fade-slider').slick({
              dots: false,
              infinite: true,
              speed: 500,
              fade: true,
              cssEase: 'linear'
            });
          },
          onEnterCompleted: function() {
              // The Transition has just finished.
          },
          onLeave: function() {
              // A new Transition toward a new page has just started.
            
          },
          onLeaveCompleted: function() {
              // The Container has just been removed from the DOM.
            $(".fade-slider").slick('destroy');
          }
        });
        initGlobalfunc();
        Barba.Pjax.start();
        Barba.Prefetch.init();
        // Barba.Utils.xhrTimeout = 10000;
        // Barba.Prefetch.ignoreClassLink();
        var isAnimating = false;
        $("#barba-wrapper").addClass("is_animating");
        if (pageHeader) {
          pageHeader.addClass("load");
        }
        var FadeTransition = Barba.BaseTransition.extend({
          start: function() {
            isAnimating = true;
            /**
             * This function is automatically called as soon the Transition starts
             * this.newContainerLoading is a Promise for the loading of the new container
             * (Barba.js also comes with an handy Promise polyfill!)
             */
            // As soon the loading is finished and the old page is faded out, let's fade the new page
            Promise.all([this.newContainerLoading, this.fadeOut(), this.scrollTop()]).then(this.fadeIn.bind(this));
          },
          scrollTop: function() {
            var deferred = Barba.Utils.deferred();
            var obj = {
              y: window.pageYOffset
            };
            TweenLite.to(obj, 0.4, {
              y: 0,
              onUpdate: function() {
                if (obj.y === 0) {
                  deferred.resolve();
                }
                window.scroll(0, obj.y);
              },
              onComplete: function() {
                deferred.resolve();
              }
            });
            return deferred.promise;
          },
          fadeOut: function() {
            var $old = $(this.oldContainer);
            /**
             * this.oldContainer is the HTMLElement of the old Container
             */
            $old.animate({
              opacity: 1
            }, 400, function() {
              $(".panel-banner").removeClass("load");
              $("#barba-wrapper").removeClass("is_animating");
              $('.bar').removeClass('animate');
              $('.mobilemenu').removeClass('active');
              $(this).removeClass('darken');
            });
            return $(this.oldContainer).stop(true, true).delay(500).animate({
              opacity: 1
            }, 300).promise();
          },
          fadeIn: function() {
            /**
             * this.newContainer is the HTMLElement of the new Container
             * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
             * Please note, newContainer is available just after newContainerLoading is resolved!
             */
            var _this = this;
            var $el = $(this.newContainer);
            $(this.oldContainer).hide();
            $el.css({
              visibility: 'visible',
            });
            $el.animate({
              opacity: 1
            }, 400, function() {
              /**
               * Do not forget to call .done() as soon your transition is finished!
               * .done() will automatically remove from the DOM the old Container
               */
              $("#barba-wrapper").addClass("is_animating");
              if (pageHeader) {
                $(".panel-banner").addClass("load");
              }
              initGlobalfunc();
              _this.done();
            });
          }
        });
        /**
         * Next step, you have to tell Barba to use the new Transition
         */
        Barba.Pjax.getTransition = function() {
          /**
           * Here you can use your own logic!
           * For example you can use different Transition based on the current page or link...
           */
          return FadeTransition;
        };
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //top slider
       
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'the_team': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.js-dropdown-item').dropdownLayer({
          'elemSelector': "js-dropdown-item",
          'containerClass': "js-dropdown-items",
          'descriptionClass': "js-description",
          'arrowClass': "js-dropdown-arrow",
          'dropdownClass': "js-dropdown",
          'dropdownContentClass': "js-dropdown-content",
          'disableDropdownClass': "js-dropdown-disable",
          'useSlideUp': true,
          'slideUpSpeed': 300,
          'useSlideDown': true,
          'slideDownSpeed': 300,
          'callOnCompleteHide': function() {},
          'callOnCompleteShow': function() {
            $("body, html").animate({
              scrollTop: $('.js-dropdown-item.active').offset().top + 200
            }, 300);
          }
        });
      }
    },
    'single': {
      init: function() {
        //console.log(post_title);
        // console.log(gmap_address);
        console.log("single");

        function initMap(fullAddress) {
          var geocoder;
          var map;
          var address = fullAddress;
          geocoder = new google.maps.Geocoder();
          var latlng = new google.maps.LatLng(-34.397, 150.644);
          var myOptions = {
            zoom: 8,
            center: latlng,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            },
            navigationControl: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };
          map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
          if (geocoder) {
            geocoder.geocode({
              'address': address
            }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
                  map.setCenter(results[0].geometry.location);
                  var infowindow = new google.maps.InfoWindow({
                    content: '<b>' + address + '</b>',
                    size: new google.maps.Size(150, 50)
                  });
                  var marker = new google.maps.Marker({
                    position: results[0].geometry.location,
                    map: map,
                    title: address
                  });
                  google.maps.event.addListener(marker, 'click', function() {
                    infowindow.open(map, marker);
                  });
                } else {
                  alert("No results found");
                }
              } else {
                alert("Geocode was not successful for the following reason: " + status);
              }
            });
          }
        }
        //google.maps.event.addDomListener(window, 'load', initialize);
        var street = $('.side-entry .espresso-venue-dv [itemprop="streetAddress"]').text();
        var city = $('.side-entry .espresso-venue-dv [itemprop="addressLocality"]').text();
        var state = $('.side-entry .espresso-venue-dv [itemprop="addressRegion"]').text();
        var zip = $('.side-entry .espresso-venue-dv [itemprop="postalCode"]').text();
        var fullAddress = street + ' ' + city + ', ' + state + ' ' + zip;
        console.log(fullAddress);
        initMap(fullAddress);
      }
    },
    'location': {
      init: function() {
        // This example displays a marker at the center of Australia.
        // When the user clicks the marker, an info window opens.
        function initMap() {
          var cyb = {
            lat: 33.896299,
            lng: -118.218278
          };
          var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: cyb
          });
          var contentString = '<div id="content">' + '<div id="siteNotice">' + '</div>' + '<h1 id="firstHeading" class="firstHeading">CYB</h1>' + '<div id="bodyContent">' + '<p>123 Main St. Los Angeles, CA 90021</p>' + '</div>' + '</div>';
          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          var marker = new google.maps.Marker({
            position: cyb,
            map: map,
            title: 'cyb'
          });
          marker.addListener('click', function() {
            infowindow.open(map, marker);
          });
          red_road_styles = [
            {
              "featureType": "landscape.man_made",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f7f1df"
            }
        ]
    },
            {
              "featureType": "landscape.natural",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#d0e3b4"
            }
        ]
    },
            {
              "featureType": "landscape.natural.terrain",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "off"
            }
        ]
    },
            {
              "featureType": "poi",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
            }
        ]
    },
            {
              "featureType": "poi.business",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
            }
        ]
    },
            {
              "featureType": "poi.medical",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#fbd3da"
            }
        ]
    },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#bde6ab"
            }
        ]
    },
            {
              "featureType": "road",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "visibility": "off"
            }
        ]
    },
            {
              "featureType": "road",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
            }
        ]
    },
            {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#ffe15f"
            }
        ]
    },
            {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#efd151"
            }
        ]
    },
            {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#ffffff"
            }
        ]
    },
            {
              "featureType": "road.local",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "black"
            }
        ]
    },
            {
              "featureType": "transit.station.airport",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#cfb2db"
            }
        ]
    },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#a2daf2"
            }
        ]
    }
];
          map.setOptions({
            styles: red_road_styles
          });
        }
        initMap();
        //AIzaSyBAzDPVYoIcD7t1l5FtXjcaM1D0vWBHfJQ
      }
    },
    'gallery': {
      init: function() {
        //end
      }
    },
    'calendar': {
      init: function() {}
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      $(function() {
        function myInit() {
          // Initialize sliders and stuff in here.
        }
        // Call myInit when document is ready.
        myInit();
        // Call myInit again after each AJAX load.
        Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML, HTMLElementContainer) {
          var currentPage = currentStatus.namespace;
          // console.log(currentPage);
          myInit();
          document.body.className = currentPage;
          //const link = currentStatus.url.split(window.location.origin)[1].substring(1);
          const link = currentStatus.url.split(window.location.origin)[1].substring(1); // get 
          const navigation = document.querySelector('.main-nav');
          const navigationLinks = navigation.querySelectorAll('.menu-item');
          const navigationLinkIsActive = navigation.querySelector("li");
          $('.main-nav').find('.active').removeClass('active');
          $('.main-nav').find('.menu-' + currentPage).addClass('active');
          $.each(currentPage.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
          });
        });
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.